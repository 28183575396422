// Global
:root {
  --accent: #f26739;
  --accent-mono: #999;
  --accent-mono-light: #ddd;
}

// Link dim
.link {
  color: var(--accent);
  opacity: 1;
  transition: opacity 0.15s ease-in;
}

.link:hover,
.link:focus {
  color: var(--accent);
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
}

.link:active {
  color: var(--accent);
  opacity: 0.8;
  transition: opacity 0.15s ease-out;
}

// SVG madness
.svg-fill-current-color {
  // Assign to an SVG element to inherit fill color
  fill: currentColor;
}

.svg-h-mirror {
  transform: scale(-1, 1);
}
